<div class="container-fluid px-5">
  <p-dialog 
   [(visible)]="visible" 
   [modal]="true" 
   [style]="{ width: '50vw' }" 
   [draggable]="false" 
   [resizable]="false"
   (onHide)="resetCommentsToggle()"
   [baseZIndex]="10000"
   [contentStyle]="{'overflow-y': 'hidden'}">
    <wg-kudos-modal *ngIf="visible" [postData]="selectedPost" [comments]="selectedPost.comments"
      [confirmDelete]="confirmDelete" (deleteCommentEvent)="confirmDeleteComment($event)"
      [showComments]="showSelectedPostComments">
    </wg-kudos-modal>
  </p-dialog>
  <div class="d-flex justify-content-between align-items-end">
    <div>
      <wg-custom-filter (filterEvent)="getFilteredData($event)"></wg-custom-filter>
    </div>
    <div class="d-flex align-items-center w-25 gap-3">
      <button class="download-button" (click)="downloadCsv()">Download</button>
      <button class="refresh-button" (click)="refreshKudosList()">
        <img src="../../../assets/svgs/refresh-icon.svg">
      </button>
      <input 
       type="text" 
       class="w-25" 
       placeholder="Search.." 
       class="form-control" 
       (keyup)="filterPosts($event)" />
    </div>
  </div>

  <div class="mt-4 table-responsive">
    <p-toast></p-toast>
    <p-confirmDialog #cdList [style]="{width: '50vw'}" [baseZIndex]="10000">
      <ng-template pTemplate="footer">
        <button class="cancel-button rounded-2" (click)="cdList.reject()">
          Cancel
        </button>

        <button class="delete-accept-button rounded-2" (click)="cdList.accept()">
          Delete
        </button>
      </ng-template>
    </p-confirmDialog>
    <table class="table table-hover align-middle mb-0 bg-white ">
      <thead>
        <tr class="table-row">
          <th class="serial-number-heading">S.No</th>
          <th class="kudos-date-heading">Date</th>
          <th class="font-weight-light value-heading">Value</th>
          <th class="giver-heading">Appreciator</th>
          <th class="receiver-heading">Reciever</th>
          <th class="content-heading">Content</th>
          <th class="delete-heading">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row" role="button" *ngFor="let kudos of filteredKudosList;let i = index;">
          <td class="serial-number" (click)="showDialog(kudos)">
            {{i + 1}}
          </td>
          <td class="kudos-date" (click)="showDialog(kudos)">
            {{ kudos.date }}
          </td>
          <td class="core-value-col px-5" (click)="showDialog(kudos)">
            {{ kudos['core_value'].name }}
          </td>
          <td class="giver-col" (click)="showDialog(kudos)">
            <div class="d-flex justify-content-center align-items-center">
              <span class="mb-1">{{kudos['created_by_fullname']}}</span>
            </div>
          </td>
          <td class="w-25 names-list receiver-col" (click)="showDialog(kudos)">
            <div class="d-flex justify-content-center align-items-center flex-wrap gap-1">
              <span *ngFor="let receiver of kudos['created_for']; let i = index"
                class="badge receiver rounded-3 receiver-{{i}}">{{receiver.fullname}}</span>
            </div>
          </td>
          <td class="w-25 content-col" (click)="showDialog(kudos)">
            {{ add3Dots(kudos['content']) }}
          </td>
          <td class="delete-col">
            <span class="comment-section" (click)="showDialog(kudos, 'comment')">
              <img
              class="comment-icon" 
              src="../../../assets/svgs/comment-icon.svg" 
              width="25" 
              height="25">
              <small>{{ getCommentsCount(kudos) }}</small>
            </span>
            <img 
             (click)="confirmDelete(kudos)" 
             src="../../../assets/svgs/delete-icon.svg" 
             width="25" 
             height="25">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>