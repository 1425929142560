<div class=" px-2 d-flex flex-column">
    <div class="d-flex flex-column post-data-container">
        <div class="d-flex gap-3 p-2 upper-part" 
         [ngStyle]="{'background-color': getCoreValueData('bgColor')}">
            <img 
             class="w-100 h-100" 
             [src]="getCoreValueData('post_url')"
             [ngStyle]="{'background-color': getCoreValueData('bgColor')}">
        </div>
        <div [ngClass]="{'border-radius': !showComments}" class="d-flex flex-column p-3 lower-part">
            <div>
                <p class="text-center m-0">
                    {{postData.content}}
                </p>
            </div>

            <div class="d-flex align-items-center justify-content-end gap-2">
                <p class="fw-semibold m-0">
                    - {{postData.created_by_fullname}}
                </p>
                <div>
                    <img src = "../../../assets/svgs/profile-icon.svg">
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="w-100 d-flex justify-content-center mb-1">
            <b>Receivers</b>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-wrap gap-1">
            <span *ngFor="let receiver of getReceiversName(); let i = index"
                class="badge receiver rounded-3 receiver-{{i}}">{{receiver}}</span>
        </div>
    </div>
    <div class="mt-2">
        <div class="comments-area" *ngIf="showComments">
            <p *ngIf="getCommentCount() > 0" class="px-2 fw-bold text-center m-0">Comments</p>
            <div class="comment-box overflow-y-auto" *ngIf="getCommentCount() > 0">
                <div class="comment d-flex justify-content-between align-items-center p-2 rounded-3 w-100"
                    *ngFor="let comment of commentsList">
                    <div class="d-flex gap-2 align-items-center single-comment-area">
                        <div class="d-flex gap-2 flex-grow align-items-center">
                            <img src = "../../../assets/svgs/profile-icon.svg">
                            <div class="d-flex flex-column justify-content-center">
                                <p class="m-0 fw-bold w-100">{{ comment.name }} </p>
                                <p class="m-0 comment-date">{{getCommentDate(comment)}}</p>
                            </div>
                        </div>
                        <p class="comment-text-section">{{ comment.content }}</p>
                    </div>
                    <img 
                     role="button" 
                     (click)="deletePostComment(comment.id)" 
                     src = "../../../assets/svgs/delete-icon.svg">
                </div>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center mt-2">
            <button class="show-comments-button p-2 justify-content-center" (click)="toggleShowComments()"
                *ngIf="getCommentCount()===1">{{showComments===true?"Hide Comments": getCommentCount() + "
                comment"}}</button>
            <button class="show-comments-button p-2 justify-content-center" (click)="toggleShowComments()"
                *ngIf="getCommentCount()>1">{{showComments===true?"Hide Comments": getCommentCount() + "
                comments"}}</button>
        </div>
    </div>
    <div>
        <div class="w-100 d-flex justify-content-between px-2 gap-2 align-items-center">
            <div class="d-flex gap-2 align-items-center">
                <img src="../../../assets/svgs/heart-icon.svg">
                <p class="m-0">{{getLikes()}}</p>
            </div>
            <div>
                <button class="delete-button">
                    <img 
                     (click)="confirmDelete(postData)" 
                     src = "../../../assets/svgs/delete-icon.svg" 
                     width="25" 
                     height="25">
                </button>
            </div>
        </div>
    </div>
</div>