import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CoreValue, DashboardTab, PostFilter } from 'src/app/shared/models/posts/post-data.model';

import { DateTimeService } from 'src/app/shared/services/datetime.service';
import { StaticDataService } from 'src/app/shared/services/static-data.service';

@Component({
  selector: 'wg-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  year: number = 0;
  quarter: number = 0;
  staticDataSubscription$ = new Subscription();
  coreValues: CoreValue[] = [];
  activeTab: DashboardTab = 'Report';
  tabs: DashboardTab[] = ['Report', 'Most Liked Posts'];

  constructor(private dateTimeService: DateTimeService,
    private spinnerService: SpinnerService,
    private staticDataService: StaticDataService
    ) {}

  ngOnInit(): void {
    this.initialize();
    this.quarter = this.dateTimeService.getCurrentQuarter();
    this.year = new Date().getFullYear();
    this.spinnerService.showSpinner();
  }

  initialize(): void {
    this.spinnerService.showSpinner();
    this.staticDataSubscription$ = this.staticDataService.fetchCoreValues().subscribe((data) => {
      for (const key in data) {
        if (data) {
          this.coreValues.push({
            sticker_url: data[key].sticker_url,
            id: key,
            name: data[key].name,
            bgColor: data[key].bgColor,
            description: data[key].description,
            post_url: data[key].post_url
          });
        }
      }
    });
  }

  filterReport(filter: PostFilter): void {
    this.year = filter.year;
    this.quarter = filter.quarter;
    this.spinnerService.showSpinner();
  }

  changeTab(tabSelected: DashboardTab): void {
    this.activeTab = tabSelected;
  }
}
