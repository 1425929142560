<div class="row">
    <div *ngFor="let postData of topPosts;let i = index" class="col-sm-4 py-4">
        <div class="rounded-btm">
            <div class="rounded-btm post d-flex flex-column">
                <div class=" d-flex flex-column post-data-container">
                    <div class="d-flex gap-3 p-2 upper-part"
                        [ngStyle]="{'background-color': getCoreValueData(postData, 'bgColor')}">
                        <img class="h-100 w-100" [src]="getCoreValueData(postData, 'post_url')"
                            [ngStyle]="{'background-color': getCoreValueData(postData, 'bgColor')}">
                    </div>
                </div>
                <div class="content lower-part rounded-btm d-flex flex-column justify-content-between">
                    <div class="d-flex flex-column p-3">
                        <div>
                            <p class=" text-center m-0">
                                {{postData.content}}
                            </p>
                        </div>
    
                        <div class="d-flex align-items-center justify-content-end gap-2">
                            <p class="fw-semibold m-0">
                                - {{postData.created_by_fullname}}
                            </p>
                            <div>
                                <img src="../../../assets/svgs/profile-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="rounded-btm">
                        <div class="w-100 d-flex justify-content-center mb-1">
                            <b>Receivers</b>
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-wrap gap-1">
                            <span *ngFor="let receiver of getReceiversName(postData); let i = index"
                                class="badge receiver rounded-3 receiver-{{i}}">{{receiver}}</span>
                        </div>
                        <div class="d-flex gap-2 mt-2 justify-content-end align-items-center p-2">
                            <img src="../../../assets/svgs/heart-icon.svg">
                            <p class="m-0">{{getLikes(postData)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>