import { Component, Input, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CoreValue, PostData } from 'src/app/shared/models/posts/post-data.model';
import { TopPostsBody } from 'src/app/shared/models/reports/report.model';
import { AWSApiCallService } from 'src/app/shared/services/awsapi.service';

@Component({
  selector: 'wg-top-posts',
  templateUrl: './top-posts.component.html',
  styleUrls: ['./top-posts.component.css']
})
export class TopPostsComponent implements OnChanges {
  @Input() year: number = 0;
  @Input() quarter: number = 0;
  topPosts: PostData[] = [];
  @Input() coreValues: CoreValue[] = [];
  monthNames = ['Jan', 'Feb', 'March', 'April', 'May',
   'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  staticDataSubscription$ = new Subscription();

  constructor(private awsApiService: AWSApiCallService,
    private spinnerService: SpinnerService
    ) {}

  ngOnChanges(): void {
    this.fetchTopPosts();
  }

  getReceiversName(postData: PostData): string[] {
    const namesArray = postData?.created_for_fullname.split('#');
    return namesArray;
  }

  getSenderName(postData: PostData): string {
    return postData?.created_by_fullname.toUpperCase();
  }

  getFormattedDate(postData: PostData): string {
    const postDate = (postData.TYPE).split('#')[0];
    const dateOfCreation = new Date(postDate);
    const localTimeDate = this.convertUTCDateToLocalDate(dateOfCreation).toLocaleString();
    const fullDate = localTimeDate.split(',')[0].split('/');
    const date = fullDate[1];
    const year = fullDate[2];
    const month = this.monthNames[Number(fullDate[0]) - 1];
    const time = localTimeDate.split(',')[1];
    return `${month} ${date}, ${year} ${time}`;
  }

  convertUTCDateToLocalDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  getCoreValueData(postData: PostData, prop: keyof CoreValue): string {
    return postData?.core_value[prop];
  }

  getLikes(postData: PostData): number {
    const likes = postData?.likes;
    if (likes) {
      return Object.keys(likes).length;
    }
    return 0;
  }

  fetchTopPosts(): void {
    this.spinnerService.showSpinner();
    this.awsApiService.getTopPosts('mostlikes', this.year, this.quarter).subscribe((data: TopPostsBody) => {
      this.mapKudosList(data.report);
      this.spinnerService.hideSpinner();
    });
  }

  mapKudosList(data: PostData[]): void {
    if (data) {
      this.topPosts = data;
      this.topPosts = this.topPosts.map(kudos => {
        const date = this.getFormattedDate(kudos);
        let postCoreValues: CoreValue = {
          bgColor: '',
          description: '',
          id: '',
          name: '',
          sticker_url: '',
          post_url: ''
        };
        const foundCoreValues = this.coreValues.find(
          (coreValue: CoreValue) => coreValue.id === kudos.core_value_id);
        if (foundCoreValues) {
          postCoreValues = foundCoreValues;
        }
        return {
          ...kudos,
          core_value: postCoreValues,
          date
        };
      });
    }
  }
}
