/* eslint-disable guard-for-in */
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import {cloneDeep} from 'lodash';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';


import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CoreValue } from 'src/app/shared/models/posts/post-data.model';
import { PostData } from 'src/app/shared/models/posts/post-data.model';
import { PostFilter } from 'src/app/shared/models/posts/post-data.model';
import { AWSApiCallService } from 'src/app/shared/services/awsapi.service';
import { DateTimeService } from 'src/app/shared/services/datetime.service';
import { StaticDataService } from 'src/app/shared/services/static-data.service';

@Component({
  selector: 'wg-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, OnDestroy {
  kudosList: PostData[] = [];
  coreValues: CoreValue[] = [];
  visible: boolean = false;
  selectedPost!: PostData;
  filteredKudosList: PostData[] = [];
  year: number = 0;
  quarter: number = 0;
  monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June',
    'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  filterKeys = ['created_by_username', 'created_by_fullname',
   'created_by_email', 'created_for_username', 'created_for_fullname',
    'created_for_email', 'core_value_name', 'content'] as const;
  showSelectedPostComments: boolean = false;

  staticDataSubscription$: Subscription = new Subscription();
  kudosListSubscription$: Subscription = new Subscription();
  deleteKudosSubscription$: Subscription = new Subscription();
  deleteKudosCommentSubscription$: Subscription = new Subscription();

  constructor(private awsApiService: AWSApiCallService,
    private staticDataService: StaticDataService,
    private dateTimeService: DateTimeService, private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    this.spinnerService.showSpinner();
    this.staticDataSubscription$ = this.staticDataService.fetchCoreValues().subscribe((data) => {
      for (const key in data) {
        if (data) {
          this.coreValues.push({
            sticker_url: data[key].sticker_url,
            id: key,
            name: data[key].name,
            bgColor: data[key].bgColor,
            description: data[key].description,
            post_url: data[key].post_url
          });
        }
      }
      this.quarter = this.dateTimeService.getCurrentQuarter();
      this.year = new Date().getFullYear();
      this.fetchKudosList(this.year, this.quarter);
    });
  }

  showDialog(postSelected: PostData, selectedCol: string = ''): void {
    this.visible = true;
    this.selectedPost = postSelected;
    if (selectedCol && selectedCol === 'comment') {
      this.showSelectedPostComments = true;
    }
  }

  updateJson(listOfObjects: any) {
    const myClonedArray = cloneDeep(listOfObjects);
    const ignoreList = ['core_value_id', 'core_value_url', 'NODE', 'is_active', 'tags', 'core_value',
     'created_by_id', 'comments'];
    for (const obj of myClonedArray) {
      for (const key in obj) {
        if (ignoreList.includes(key)) {
          delete obj[key];
          continue;
        }
        if (key === 'core_value_name') {
          obj['core value'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'TYPE') {
          const date = obj[key].split('#')[0];
          obj.date = date.toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_by_email') {
          obj['kudos creator email id'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_for_email') {
          obj['kudos receivers email id'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_by_username') {
          obj['kudos creator username'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_for_fullname') {
          obj['kudos receivers fullname'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_for_username') {
          obj['kudos receivers username'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if (key === 'created_by_fullname') {
          obj['kudos creator fullname'] = obj[key].toString();
          delete obj[key];
          continue;
        }
        if ((obj.hasOwnProperty(key) && typeof(obj[key]) === 'string')) {
          continue;
        }
        if ((obj.hasOwnProperty(key) && typeof(obj[key]) === 'boolean')) {
          obj[key] = obj[key].toString();
          continue;
        }
        if (obj.hasOwnProperty(key) && (!Array.isArray(obj[key])) ) {
          obj[key] = Object.keys(obj[key]).length;
        }
        if (obj.hasOwnProperty(key) && (Array.isArray(obj[key]))) {
          if (key === 'created_for') {
            obj['kudos receivers'] = this.fetchCreatedForUsername(obj[key]);
            delete obj[key];
          } else {
            obj[key] = ((obj[key]).length).toString();
          }
        }
      }
    }
    return myClonedArray;
  }

  fetchCreatedForUsername(createdFor: []): string {
    const fullnameList: string[] = [];
    for (const item of createdFor) {
      if (item['fullname'] !== null) {
        fullnameList.push(item['fullname']);
      }
    }
    return fullnameList.toString();
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer]);
     fileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  downloadCsv(): void {
    const data = this.updateJson(this.filteredKudosList);
    this.exportAsExcelFile(data, 'kudos');
  }

  resetCommentsToggle(): void {
    this.showSelectedPostComments = false;
  }

  getCommentsCount(kudos: PostData): number {
    if (kudos.comments) {
      return Object.entries(kudos.comments).length;
    };
    return 0;
  }

  fetchKudosList(year: number, quarter: number): void {
    this.spinnerService.showSpinner();
    this.kudosListSubscription$ = this.awsApiService.getKudosList(year, quarter).subscribe((data: PostData[]) => {
      this.mapKudosList(data);
      this.spinnerService.hideSpinner();
    });
  }

  mapKudosList(data: PostData[]): void {
    if (data) {
      this.kudosList = data;
      this.kudosList = this.kudosList.map(kudos => {
        const date = this.getFormattedDate(kudos);
        let postCoreValues: CoreValue = {
          bgColor: '',
          description: '',
          id: '',
          name: '',
          sticker_url: '',
          post_url: ''
        };
        const foundCoreValues = this.coreValues.find(
          (coreValue: CoreValue) => coreValue.id === kudos.core_value_id);
        if (foundCoreValues) {
          postCoreValues = foundCoreValues;
        }
        return {
          ...kudos,
          core_value: postCoreValues,
          date
        };
      });
      this.filteredKudosList = this.kudosList;
    }
  }

  refreshKudosList(): void {
    this.spinnerService.showSpinner();
    this.quarter = this.dateTimeService.getCurrentQuarter();
    this.year = new Date().getFullYear();
    this.fetchKudosList(this.year, this.quarter);
  }

  getFilteredData(filter: PostFilter): void {
    this.year = filter.year;
    this.quarter = filter.quarter;
    this.fetchKudosList(this.year, this.quarter);
  }

  filterPosts(event: any): void {
    const filterValue = event.target.value;
    const filteredKudos: PostData[] = [];

    this.kudosList?.forEach(kudos => {
      Object.keys(kudos).map((key) => {
        const filterKey = key as typeof this.filterKeys[number];
        if (this.filterKeys.includes(filterKey)
        && kudos[key as keyof Pick<PostData, typeof this.filterKeys[number]>]
        .toLowerCase().includes(filterValue)) {
          if (!filteredKudos.includes(kudos)) {
            filteredKudos.push(kudos);
          }
        }
      });
    });
    this.filteredKudosList = filteredKudos;
  }

  add3Dots(content: string): string {
    const limit = 100;
    const dots: string = '...';
    if (content.length > limit) {
      content = content.substring(0, limit) + dots;
    }
    return content;
  }

  getFormattedDate(postData: PostData): string {
    const postDate = (postData.TYPE).split('#')[0];
    const dateOfCreation = new Date(postDate);
    const localTimeDate = this.convertUTCDateToLocalDate(dateOfCreation).toLocaleString('en-us');
    const fullDate = localTimeDate.split(',')[0].split('/');
    const date = fullDate[1];
    const year = fullDate[2];
    const month = this.monthNames[Number(fullDate[0]) - 1];
    const time = localTimeDate.split(',')[1];
    return `${month} ${date}, ${year} ${time}`;
  }

  convertUTCDateToLocalDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  deleteKudos(kudos: PostData): void {
    this.visible = false;
    this.spinnerService.showSpinner();
    this.deleteKudosSubscription$ = this.awsApiService.deleteKudos(kudos.TYPE).subscribe(() => {
      this.fetchKudosList(this.year, this.quarter);
    });
  }

  deleteComment(postId: string, commentId: string): void {
    this.visible = false;
    this.deleteKudosCommentSubscription$ = this.awsApiService.deleteComment(postId, commentId).subscribe(() => {
      this.spinnerService.hideSpinner();
      this.fetchKudosList(this.year, this.quarter);
    });
  }

  confirmDelete(kudos: PostData): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the kudos?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteKudos(kudos);
      }
    });
  }

  confirmDeleteComment(event: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the comment for kudos?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinnerService.showSpinner();
        this.deleteComment(event.postId, event.commentId);
      }
    });
  }

  ngOnDestroy(): void {
    this.staticDataSubscription$?.unsubscribe();
    this.kudosListSubscription$?.unsubscribe();
    this.deleteKudosSubscription$?.unsubscribe();
    this.deleteKudosCommentSubscription$?.unsubscribe();
  }
}
