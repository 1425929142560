<div class="sticky-top nav bg-white container-fluid d-flex flex-column px-5">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <img src="https://cyberriskleaders.com/wp-content/uploads/2018/03/Watchguard_logo835x396.jpg"
        class="img-fluid wg-logo"
      />
    </div>
    <h3 class="mt-1">Kudos Admin Portal</h3>
    <div class="d-flex align-items-center gap-3">
        <p class="text-center pt-3 text-decoration-underline">{{ username }}</p>
      <button (click)="logout()" class="btn btn-secondary logout-btn">Logout</button>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="cntr d-flex justify-content-between rounded">
      <button 
        *ngFor="let tab of navTabs; let i = index"
        [ngClass]="{'disabled-nav-list-item': disabledTabs.includes(tab.label),
         'border-left': i === 0, 'border-right': i === 3}"
        [disabled]="disabledTabs.includes(tab.label)"
        class="nav-list-item p-2"
        routerLinkActive="activeTab"
        routerLink="{{tab.route}}">{{tab.label}}
        <img
         class="mx-2 mb-1"
         height="20"
         width="20" 
         *ngIf="disabledTabs.includes(tab.label)" 
         src="../../assets/images/under-construction.png" 
         alt="under construction image">
      </button>
    </div>
  </div>
</div>
