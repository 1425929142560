import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'wg-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  username: string = '';
  activeTab = '';
  disabledTabs: string[] = ['Values', 'Users'];
  navTabs = [
    {
      label: 'Dashboard',
      route: 'dashboard'
    },
    {
      label: 'Kudos List',
      route: 'kudoslist'
    },
    {
      label: 'Values',
      route: 'values'
    },
    {
      label: 'Users',
      route: 'users'
    }
  ];

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  ngOnInit(): void {
    this.username = JSON.parse(sessionStorage.getItem('username') || '');
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
