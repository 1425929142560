import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartModule } from 'primeng/chart';

import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { BarGraphsComponent } from './dashboard-main/graphs/bar-graphs/bar-graphs.component';
import { PieChartComponent } from './dashboard-main/graphs/pie-chart/pie-chart.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TopPostsComponent } from './dashboard-main/top-posts/top-posts.component';

@NgModule({
  declarations: [
    DashboardMainComponent,
    BarGraphsComponent,
    PieChartComponent,
    TopPostsComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ChartModule
  ]
})
export class DashboardModule { }
