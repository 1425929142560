import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class DateTimeService {
    getCurrentQuarter(): number {
        const month = new Date().getMonth();
        const quarter = Math.floor((month) / 3 + 1);
        return quarter;
      }
}
