import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { SessionService } from '../services/session.service';
import { environment } from '../../../environments/environment';
import { InterceptorSkip } from '../services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers && request.headers.has(InterceptorSkip)) {
      const headers = request.headers.delete(InterceptorSkip);
      request = request.clone({ headers });
    } else {
      const idtoken = this.sessionService.getSessionData('idtoken');
      request = request.clone({
        headers: request.headers.set('Authorization', String(idtoken))
      });
    }
    const localEnvironment = window.location.href.indexOf('.com') === -1;
    const serverlessApi = this.isServerlessApi(request.url);

    if (localEnvironment && serverlessApi) {
      request = this.applyCorsPolicy(request);
    }
    return next.handle(request);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/wkportal/.test(url)) {
      return true;
    }
    return false;
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/wkportal/.test(req.url)) {
      return req.clone({
        url: req.url.replace(environment.BASE_API, '')
      });
    }
    return req;
  }
}
