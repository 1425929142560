import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { PostFilter } from '../../models/posts/post-data.model';
import { DateTimeService } from '../../services/datetime.service';

@Component({
  selector: 'wg-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.css']
})
export class CustomFilterComponent implements OnInit {
  @Output() filterEvent = new EventEmitter<PostFilter>();

  yearValue: number = this.getCurrentYear();
  quarterValue: string = 'Q' + this.getCurrentQuarter();
  quarters: number[] = [];

  constructor(private dateTimeService: DateTimeService) { }

  ngOnInit(): void {
    for (let i = 0; i < this.getCurrentQuarter(); i++) {
      this.quarters.push(i + 1);
    }
  }

  updateFilter(): void {
    if (this.yearValue === this.getCurrentYear()) {
      for (let i = 0; i < this.getCurrentQuarter(); i++) {
        this.quarters.push(i + 1);
      }
    } else {
      this.quarters = [];
      for (let i = 0; i < 4; i++) {
        this.quarters.push(i + 1);
      }
    }
    this.filterEvent.emit({
      quarter: +this.quarterValue.slice(1),
      year: this.yearValue
    });
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  getCurrentQuarter(): number {
    const quarter = this.dateTimeService.getCurrentQuarter();
    return quarter;
  }

  getFilterYears(): number[] {
    const currentYear = new Date().getFullYear();
    return [currentYear - 2, currentYear - 1, currentYear];
  }
}
