import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subscription } from 'rxjs';

import { PieChartData } from 'src/app/shared/models/charts/PieChartData.model';
import { PieChartReportBody } from 'src/app/shared/models/reports/report.model';
import { AWSApiCallService } from 'src/app/shared/services/awsapi.service';

@Component({
  selector: 'wg-pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() year: number = 0;
  @Input() quarter: number = 0;

  data: PieChartData = {
    datasets: [],
    labels: []
  };
  options: any;
  plugins = [ChartDataLabels];
  reportSubscription$: Subscription = new Subscription();

  constructor(private awsApiService: AWSApiCallService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    this.data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgb(51, 51, 51)',
            'rgb(179, 35, 23)',
            'rgb(3, 89, 150)',
            'rgb(232, 20, 16)',
            'rgb(39, 149, 227)',
            'rgb(128, 128, 128)'
          ],
          hoverBackgroundColor: [
            'rgb(173, 173, 173)',
            'rgb(241, 153, 146)',
            'rgb(115, 195, 252)',
            'rgb(248, 159, 157)',
            'rgb(169, 213, 244)',
            'rgb(204, 204, 204)'
          ]
        }
      ]
    };

    this.options = {
      layout: {
        padding: {
          left: 90,
          right: 90
        }
      },
      plugins: {
        legend: false
      }
    };
  }

  ngOnChanges(): void {
    this.fetchData();
  }

  mapData(updatedData: PieChartReportBody): void {
    this.data.labels = updatedData.report.corevalues;
    this.data.datasets[0].data = updatedData.report.data;
    const newData = this.data;
    this.data = Object.assign({}, newData);
    this.setDataLabels();
  }

  setDataLabels(): void {
    this.options.plugins.datalabels = {
      labels: {
        name: {
          formatter: (_: any, ctx: any) => {
            return ctx.chart.data.labels[ctx.dataIndex];
          },
          color: '#000',
          anchor: 'end',
          align: 'end'
        },
        percentage: {
          formatter: (_: any, ctx: any) => {
            const dataList = ctx.chart.data.datasets[ctx.datasetIndex].data;
            const sum = dataList.reduce((accumulator: number, currentValue: number) => {
              return accumulator + currentValue;
            }, 0);
            return (ctx.chart.data.datasets[ctx.datasetIndex].data[ctx.dataIndex]
              / sum * 100).toFixed(1) + '%';
          },
          color: '#fff',
          anchor: 'center',
          align: 'center'
        }
      }
    };
  }

  fetchData(): void {
    this.reportSubscription$ = this.awsApiService.getPieChartReport('corevalues', this.year, this.quarter).subscribe(
      (responseData: PieChartReportBody) => {
        this.mapData(responseData);
      });
  }

  ngOnDestroy(): void {
    this.reportSubscription$.unsubscribe();
  }
}


