import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, catchError, map, throwError } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { SpinnerService } from '../components/spinner/spinner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private spinnerService: SpinnerService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((response: any) => {
            if (response.status === 200) {
                this.spinnerService.hideSpinner();
            }
            return response;
        }), catchError(errorResponse => {
            this.spinnerService.hideSpinner();
            if (errorResponse.status === 401 || errorResponse.status === 403) {
                this.authenticationService.logout();
                this.router.navigate(['/auth/login']);
            }
            const error = errorResponse.error.message ?? errorResponse.statusText;
            return throwError(error);
        }));
    }
}
