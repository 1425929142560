import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuesMainComponent } from './values-main/values-main.component';
import { ValuesRoutingModule } from './values-routing.module';
import { ValueCardComponent } from './values-main/value-card/value-card.component';

@NgModule({
  declarations: [
    ValuesMainComponent,
    ValueCardComponent
  ],
  imports: [
    CommonModule,
    ValuesRoutingModule
  ],
  exports:[
    ValuesMainComponent
  ]
})
export class ValuesModule { }
