import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subscription } from 'rxjs';

import { BarGraphData } from 'src/app/shared/models/charts/BarGraphData.model';
import { BarGraphReportBody } from 'src/app/shared/models/reports/report.model';
import { AWSApiCallService } from 'src/app/shared/services/awsapi.service';

@Component({
  selector: 'wg-bar-graphs',
  templateUrl: './bar-graphs.component.html',
  styleUrls: ['./bar-graphs.component.css']
})
export class BarGraphsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() year: number = 0;
  @Input() quarter: number = 0;
  @Input() graphType: 'giver' | 'receiver' = 'giver';

  data: BarGraphData = {
    datasets: [],
    labels: []
  };
  options: any;
  plugins = [ChartDataLabels];
  reportSubscription$: Subscription = new Subscription();

  constructor(private awsApiService: AWSApiCallService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgb(179, 35, 23, 0.2)',
            'rgb(3, 89, 150, 0.2)'
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(179, 35, 23)',
            'rgb(3, 89, 150, 0.2)'
          ],
          borderWidth: 1,
          names: []
        }
      ]
    };

    this.options = {
      plugins: {
        legend: false,
        datalabels: {
          formatter: (_: string, ctx: any) => {
            return ctx.chart.data.datasets[ctx.datasetIndex]
              .names[ctx.dataIndex].split(' ')[0];
          },
          color: '#000'
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  ngOnChanges(): void {
    this.fetchData();
  }

  mapData(updatedData: BarGraphReportBody): void {
    const report = updatedData.report[0];
    this.data.labels = report.months.map((month: string) => month.split('-')[0]);
    this.data.datasets[0].names = report.names;
    this.data.datasets[0].data = report.data;
    const newData = this.data;
    this.data = Object.assign({}, newData);
  }

  fetchData(): void {
    const reportQuery = this.graphType === 'giver' ? 'createdby' : 'createdfor';
    this.reportSubscription$ = this.awsApiService
    .getBarGraphReport(reportQuery, this.year, this.quarter).subscribe(
      (responseData: BarGraphReportBody) => {
        this.mapData(responseData);
      });
  }

  ngOnDestroy(): void {
    this.reportSubscription$?.unsubscribe();
  }
}
