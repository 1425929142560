import { Component, OnDestroy, OnInit } from '@angular/core';

import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  showSpinner: boolean = false;
  spinnerSubscription$: Subscription = new Subscription();

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerSubscription$ = this.spinnerService.spinnerState.subscribe((state) => {
      this.showSpinner = state;
    });
  }

  ngOnDestroy(): void {
    this.spinnerSubscription$?.unsubscribe();
  }
}
