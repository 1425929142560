import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from './shared/services/session.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  authStatus = false;
  authSubcription$: Subscription = new Subscription();
  constructor(private sessionService: SessionService, private router: Router) { }

    ngOnInit(): void {
      this.authSubcription$ = this.sessionService.authStatus.subscribe(() => {
        this.setAuthStatus();
      });
    }

    setAuthStatus(): void {
      this.authStatus = this.sessionService.isLoggedIn();
      if (this.authStatus === false) {
        this.router.navigate(['login']);
      }
    }
    getAuthStatus() {
      return this.sessionService.isLoggedIn();
    }
    ngOnDestroy(): void {
      this.authSubcription$?.unsubscribe();
    }
}
