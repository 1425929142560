import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, catchError, throwError, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BarGraphReportBody, PieChartReportBody, ReportResponse } from '../models/reports/report.model';
import { PostData } from '../models/posts/post-data.model';


@Injectable({
    providedIn: 'root'
})
export class AWSApiCallService {
    constructor(private httpClient: HttpClient) { }

    getQuarter(): string {
        const month = new Date().getMonth();
        const quarter = Math.floor((month) / 3 + 1);
        return 'Q' + quarter;
    }

    getKudosList(year: number, quarter: number): Observable<PostData[]> {
        let url: string = environment.BASE_API + environment.GET_KUDOS_ENDPOINT;
        url = url.replace('{0}', 'Q' + quarter);
        url = url.replace('{1}', year.toString());
        return this.httpClient.get<Response>(url)
            .pipe(
                map((response: Response) => JSON.parse(JSON.stringify(response.body))),
                catchError(this.handleError)
            );
    }

    deleteKudos(type: string) {
        const url: string = environment.BASE_API + environment.DELETE_KUDOS_ENDPOINT +
            encodeURIComponent(type);
        return this.httpClient.delete<Response>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteComment(postId: string, commentId: string) {
        let deleteEndpoint = environment.DELETE_KUDOS_COMMENT_ENDPOINT;
        deleteEndpoint = deleteEndpoint.replace('{0}', encodeURIComponent(postId));
        deleteEndpoint = deleteEndpoint.replace('{1}', commentId);
        const url: string = environment.BASE_API + deleteEndpoint;
        return this.httpClient.delete<Response>(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    getPieChartReport(query: string, year: number, quarter: number): Observable<PieChartReportBody> {
        let url: string = environment.BASE_API + environment.GET_REPORT_ENDPOINT;
        url = url.replace('{0}', query);
        url = url.replace('{1}', year.toString());
        url = url.replace('{2}', 'Q' + quarter);
        return this.httpClient.get<ReportResponse>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response.body)))
        );
    }

    getBarGraphReport(query: string, year: number, quarter: number): Observable<BarGraphReportBody> {
        let url: string = environment.BASE_API + environment.GET_REPORT_ENDPOINT;
        url = url.replace('{0}', query);
        url = url.replace('{1}', year.toString());
        url = url.replace('{2}', 'Q' + quarter);
        return this.httpClient.get<ReportResponse>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response.body)))
        );
    }

    getTopPosts(query: string, year: number, quarter: number) {
        let url: string = environment.BASE_API + environment.GET_REPORT_ENDPOINT;
        url = url.replace('{0}', query);
        url = url.replace('{1}', year.toString());
        url = url.replace('{2}', 'Q' + quarter);
        return this.httpClient.get<ReportResponse>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response.body)))
        );
    }

    handleError(error: any) {
        let errorMessage = '';
        console.log(error);
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        }
        return throwError(() => {
            return errorMessage;
        });
    }
}
