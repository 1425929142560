import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CoreValue, GetCoreValueResponse,
   CoreValueResponseBody } from '../models/posts/post-data.model';

@Injectable({providedIn: 'root'})
export class StaticDataService {

    coreValues: CoreValue[] = [];

    constructor( private http: HttpClient) {}

    fetchCoreValues() {
      const url = environment.BASE_API + environment.GET_CORE_VALUES_ENDPOINT;
      return this.http.get<GetCoreValueResponse>(url).pipe(
        map((response: GetCoreValueResponse) => response.body));
    }

    setCoreValues(): void {
      this.fetchCoreValues().subscribe((response) => {
        const responseBody: CoreValueResponseBody = response;
        for (const key in responseBody) {
          if (responseBody) {
            this.coreValues.push({
              sticker_url: responseBody[key].sticker_url,
              id: key,
              name: responseBody[key].name,
              bgColor: responseBody[key].bgColor,
              description: responseBody[key].description,
              post_url: responseBody[key].post_url
            });
          }
        }
      });
    }

    getCoreValues(): CoreValue[] {
      return this.coreValues;
    }
}
