<div class="d-flex gap-2 w-75 align-items-center gap-1">
    <div class="form-group d-flex gap-2 align-items-center">
        <label for="">Year</label>
        <select [(ngModel)]="yearValue" class="px-2 py-1 rounded-1" (change)="updateFilter()">
            <option 
            [attr.selected]="i === getFilterYears().length - 1 ? true : null" 
            *ngFor="let year of getFilterYears(); let i = index;">{{year}}</option>
        </select>
    </div>
    <div class="form-group d-flex gap-2 align-items-center">
        <label for="">Quarter</label>
        <select [(ngModel)]="quarterValue" class="px-2 py-1 rounded-1" (change)="updateFilter()">
            <option *ngFor = "let quarter of quarters">{{"Q" + quarter}}</option>
        </select>
    </div>
</div>