import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { LoginRequest } from '../../shared/models/authentication/auth.model';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SessionService } from '../../shared/services/session.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnDestroy, OnInit {
  loginData: LoginRequest = {};
  loginSubscription$: Subscription = new Subscription();
  passwordValidationSubscription$: Subscription = new Subscription();
  isPasswordCorrect = true;
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.authenticationService.isPasswordCorrect$.subscribe((data) => {
      this.isPasswordCorrect = data;
    });
  }

  onSubmit(loginForm: NgForm): void {
    if (loginForm.valid) {
      this.spinnerService.showSpinner();
      this.loginSubscription$ = this.authenticationService.login(this.loginData).subscribe(
        () => {
        this.sessionService.updateAuthStatus(true, this.loginData);
        this.sessionService.isLoggedIn();
        this.router.navigate(['dashboard']);
      });
    }
  }

  ngOnDestroy(): void {
    this.loginSubscription$?.unsubscribe();
  }
}
