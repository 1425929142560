import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';


import { CustomFilterComponent } from './components/custom-filter/custom-filter.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CustomFilterComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    ToastModule,
    FormsModule
  ],
  exports:[
    CustomFilterComponent
  ]
})
export class SharedModule { }
