export const environment = {
  production: false,
  BASE_API: 'https://dev.api.kudos.wginb.com',
  LOGIN_API: '/wkportallogin/portal/login',
  GET_KUDOS_ENDPOINT: `/wkportalapis/post/list?quarter={0}&year={1}`,
  GET_CORE_VALUES_ENDPOINT: '/wkportalapis/value/1',
  DELETE_KUDOS_ENDPOINT: `/wkportalapis/post/`,
  DELETE_KUDOS_COMMENT_ENDPOINT: `/wkportalapis/post/{0}/comment/{1}`,
  GET_REPORT_ENDPOINT: `/wkportalapis/report/{0}?year={1}&quarter={2}`,
  RESET_PASSWORD: "https://dev.portal.reset.wginb.com"

};
