<div class="d-flex gap-5 p-2 mt-2 justify-content-center align-items-center">
    <input class="form-check-input" type="checkbox">
    <div class="d-flex flex-column gap-1 p-2">
        <label>Value Name</label>
        <input type="text" class="form-control">
    </div>
    <div>
        <img src="https://placehold.co/150x150" alt="" class="rounded">
    </div>
    <div>
        <textarea class="form-control" cols="50" rows="6" placeholder="Description..."></textarea>
    </div>
</div>