import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ListComponent } from './list/list.component';
import { KudoslistRoutingModule } from './kudoslist-routing.module';
import { SharedModule } from '../shared/shared.module';
import { KudosModalComponent } from './kudos-modal/kudos-modal.component';
@NgModule({
  declarations: [
    ListComponent,
    KudosModalComponent
  ],
  imports: [
    CommonModule,
    KudoslistRoutingModule,
    SharedModule,
    DialogModule,
    ButtonModule,
    ConfirmDialogModule,
    TooltipModule,
    ToastModule
  ],
  exports:[
    ListComponent
  ],
  providers: [
    ConfirmationService,
    MessageService
  ]
})
export class KudoslistModule { }
