<main class="bg-white d-flex flex-column container-fluid m-auto px-5">
  <div class="fixed-cntr container-fluid px-5">
    <div class="sticky-cntr d-flex justify-content-center gap-4">
      <div>
          <wg-custom-filter (filterEvent)="filterReport($event)"></wg-custom-filter>
      </div>
      <div class="d-flex justify-content-center rounded">
        <button 
          *ngFor="let tab of tabs; let i = index"
          class="tab-item"
          [ngClass]="{'first-tab-item': i === 0, 'second-tab-item': i === 1, 'active-tab': tab === activeTab}"
          (click) = "changeTab(tab)"
          >{{ tab }}
        </button>
      </div>

    </div>
  </div>
  <div *ngIf="activeTab === 'Report'" class="main-area d-flex flex-column mt-2">
    <div class="d-flex justify-content-around gap-2 p-4">
      <div class="bar-graph d-flex flex-column align-items-center">
        <p class="fw-bold">Top Appreciators</p>
        <wg-bar-graphs [year]="year" [quarter]="quarter" [graphType]="'giver'" class="w-100">
        </wg-bar-graphs>
      </div>
      <div class="bar-graph d-flex flex-column align-items-center">
        <p class="fw-bold">Top Receivers</p>
        <wg-bar-graphs [year]="year" [quarter]="quarter" [graphType]="'receiver'" class="w-100">
        </wg-bar-graphs>
      </div>
    </div>
    <div class="d-flex justify-content-center p-4">
      <div class="bar-graph w-100 d-flex flex-column align-items-center">
        <p class="fw-bold">Core Values</p>
      <wg-pie-chart [year]="year" [quarter]="quarter" class="pie-chart"></wg-pie-chart>
      </div>
    </div>
  </div>
  <div class="px-4 top-posts-area">
    <wg-top-posts *ngIf = "coreValues.length > 0 && activeTab === 'Most Liked Posts'" [year]="year" [quarter]="quarter" [coreValues]="coreValues"></wg-top-posts>
  </div>
</main>