import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { LoginRequest, LoginResponse } from '../models/authentication/auth.model';

@Injectable({ providedIn: 'root' })

export class SessionService {
    authStatus = new BehaviorSubject(false);

    getSessionStorageItem(key: string) {
        return sessionStorage.getItem(key);
    }

    updateAuthStatus(status: boolean, loginData: LoginRequest): void {
        sessionStorage.setItem('username', JSON.stringify(loginData.username));
        this.authStatus.next(status);
    }
    setSessionObject(sessionData: LoginResponse): void {
        sessionStorage.setItem('wginb-session', JSON.stringify(sessionData));
        this.authStatus.next(true);
    }

    getSessionData(value: keyof LoginResponse): string | number {
        const session: LoginResponse = JSON.parse(this.getSessionStorageItem('wginb-session')!);
        return session[value];
    }

    getTokenData(value: keyof LoginResponse | string): string {
        const session: LoginResponse = JSON.parse(this.getSessionStorageItem('wginb-session')!);
        const tokenData: any = jwt_decode(session.idtoken);
        return tokenData[value];
    }

    isLoggedIn(): boolean {
        const session: LoginResponse = JSON.parse(this.getSessionStorageItem('wginb-session')!);
        if (session === null) {
            return false;
        } else {
            const value: string = 'exp';
            const timeExpired = new Date().getTime() > (parseInt(this.getTokenData(value), 10)) * 1000;
            return !timeExpired;
        }
    }

    clearSession(): void {
        sessionStorage.removeItem('wginb-session');
        this.authStatus.next(false);
    }
}
