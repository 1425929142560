import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';

import { CoreValue } from 'src/app/shared/models/posts/post-data.model';
import { Likes, Comment, DeleteCommentEvent, PostData, Comments } from 'src/app/shared/models/posts/post-data.model';
import { AWSApiCallService } from 'src/app/shared/services/awsapi.service';

@Component({
  selector: 'wg-kudos-modal',
  templateUrl: './kudos-modal.component.html',
  styleUrls: ['./kudos-modal.component.css']
})
export class KudosModalComponent implements OnInit, OnDestroy {
  @Input() postData!: PostData;
  @Input() comments: Comments = {};
  @Input() confirmDelete!: (kudos: PostData) => void;
  @Input() showComments: boolean = false;
  @Output() deleteCommentEvent = new EventEmitter<DeleteCommentEvent>();

  usersLiked: string = '';
  monthNames = ['Jan', 'Feb', 'March', 'April', 'May',
   'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  commentsList: Comment[] = [];
  deleteSubscription$: Subscription = new Subscription();

  constructor(private awsApiService: AWSApiCallService,
     private spinnerService: SpinnerService,
     private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    for (const key in this.postData?.likes) {
      if ( this.postData?.likes) {
        const user: Likes = this.postData?.likes[key];
        this.usersLiked += user.name + '\n';
      }
    }
    for (const [key, value] of Object.entries(this.comments)) {
      const newCommentVal = {
        ...value,
        id: key
      };
      this.commentsList.push(newCommentVal);
    }
  }

  deleteKudos(): void {
    this.deleteSubscription$ = this.awsApiService.deleteKudos(this.postData?.TYPE).subscribe(() => {
      window.location.reload();
    });
  }

  deletePostComment(commentId: string) {
    this.deleteCommentEvent.emit({
      postId: this.postData?.TYPE,
      commentId
    });
  }

  getReceiversName(): string[] {
    const namesArray = this.postData?.created_for_fullname.split('#');
    return namesArray;
  }

  getSenderName(): string {
    return this.postData?.created_by_fullname.toUpperCase();
  }

  getCoreValueData(prop: keyof CoreValue): string {
    return this.postData?.core_value[prop];
  }

  getLikes(): number {
    const likes = this.postData?.likes;
    if (likes) {
      return Object.keys(likes).length;
    }
    return 0;
  }

  getCommentCount(): number {
    if (this.commentsList) {
      return this.commentsList.length;
    };
    return 0;
  }

  toggleShowComments(): void {
    this.showComments = !this.showComments;
  }

  getCommentDate(c: Comment): string {
    const fullDate = new Date(c.comment_datetime);
    const year = fullDate.getFullYear();
    const month = this.monthNames[fullDate.getMonth()];
    const date = fullDate.getDate();
    return `${month} ${date}, ${year}`;
  }

  getFormattedDate(): string {
    const postDate = (this.postData.TYPE).split('#')[0];
    const dateOfCreation = new Date(postDate);
    const localTimeDate = this.convertUTCDateToLocalDate(dateOfCreation).toLocaleString();
    const fullDate = localTimeDate.split(',')[0].split('/');
    const date = fullDate[1];
    const year = fullDate[2];
    const month = this.monthNames[Number(fullDate[0]) - 1];
    const time = localTimeDate.split(',')[1];
    return `${month} ${date}, ${year} ${time}`;
  }

  convertUTCDateToLocalDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  ngOnDestroy(): void {
    this.deleteSubscription$?.unsubscribe();
  }
}
